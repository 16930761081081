import React from "react";
import { get } from "lodash";
import NO_IMG from "../images/no-image.png";
import ZIP from "../images/zip-file.png";
import PDF from "../images/pdf-file.png";

const EuroCurrencyFormatter = (price = 0) => {
  return price ? price.toLocaleString('en-IE', {
    style: 'currency',
    currency: 'EUR',
  }): '';
}

const PropertyUploaderConfig = {
  rootPath: "property-uploader/api",
  rootGraphQLPath: "graphql",
  "new-developments": {
    endpoint: {
      //"uri": "pu-property",
      "uri": "content-manager/collection-types/application::new-developments.new-developments",
      // "uri": "new-developments",
      excludeApiPrefix: true,
      "getAllNewDev": {
        "uri": "new-developments",
        excludeApiPrefix: true,
      },
      "delete": {
        "uri": "delete/1/new-developments",
        /*To delete a data permenently
        "uri": "delete/2/property",*/
        /*To perform above actions for plugins
        "uri": "delete/2/property_uploader::pu-property",*/
        excludeApiPrefix: false,
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Reference ID',
          selector: row => row.crm_id,
          sortable: true,
          sortField: 'crm_id',
          width: "15%"
        },
        {
          name: 'Status',
          selector: row => row.status,
          sortable: true,
          sortField: 'status',
          width: "9%"
        },
        {
          name: 'Address',
          selector: row => row.display_address,
          sortable: true,
          sortField: 'display_address',
          width: "24%",
          cell: property => (
            <>
              <div style={{ 'word-break': 'normal' }}>
                {get(property, 'display_address')}
              </div>
            </>
          )
        },
        {
          name: 'Price',
          sortable: true,
          sortField: 'price',
          cell: property => {
            let priceFreq = (property.search_type === 'lettings') ? property.price_qualifier : '';
            switch (priceFreq) {
              case 'Per Annum':
                priceFreq = 'pa';
                break;
              case 'Per Month':
                priceFreq = 'pm';
                break;
              case 'Per Week':
                priceFreq = 'pw';
                break;
              case 'POA':
                priceFreq = 'poa';
                break;
              default:
                priceFreq = '';
            }
            return (
              <>
                {
                  `${EuroCurrencyFormatter(get(property, 'price'))} ${priceFreq}`
                }
              </>
            )
          },
          width: "13%"
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "child-properties": {
    endpoint: {
      "uri": "content-manager/collection-types/application::property.property",
      excludeApiPrefix: true,
      "delete": {
        "uri": "delete/1/property",
        /*To delete a data permenently
        "uri": "delete/2/property",*/
        /*To perform above actions for plugins
        "uri": "delete/2/property_uploader::pu-property",*/
        excludeApiPrefix: false,
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Reference ID',
          selector: row => row.crm_id,
          sortable: true,
          sortField: 'crm_id',
          width: "15%"
        },
        {
          name: 'Status',
          selector: row => row.status,
          sortable: true,
          sortField: 'status',
          width: "9%"
        },
        {
          name: 'Address',
          selector: row => row.display_address,
          sortable: true,
          sortField: 'display_address',
          width: "24%",
          cell: property => (
            <>
              <div style={{ 'word-break': 'normal' }}>
                {get(property, 'display_address')}
              </div>
            </>
          )
        },
        {
          name: 'Price',
          sortable: true,
          sortField: 'price',
          cell: property => {
            let priceFreq = (property.search_type === 'lettings') ? property.price_qualifier : '';
            switch (priceFreq) {
              case 'Per Annum':
                priceFreq = 'pa';
                break;
              case 'Per Month':
                priceFreq = 'pm';
                break;
              case 'Per Week':
                priceFreq = 'pw';
                break;
              case 'POA':
                priceFreq = 'poa';
                break;
              default:
                priceFreq = '';
            }
            return (
              <>
                {
                  `${EuroCurrencyFormatter(get(property, 'price'))} ${priceFreq}`
                }
              </>
            )
          },
          width: "13%"
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "properties": {
    endpoint: {
      // "uri": "properties",
      "uri": "content-manager/collection-types/application::property.property",
      excludeApiPrefix: true,
      "delete": {
        "uri": "delete/1/property",
        /*To delete a data permenently
        "uri": "delete/2/property",*/
        /*To perform above actions for plugins
        "uri": "delete/2/property_uploader::pu-property",*/
        excludeApiPrefix: false,
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Reference ID',
          selector: row => row.crm_id,
          sortable: true,
          sortField: 'crm_id',
          width: "15%"
        },
        {
          name: 'Department',
          sortable: true,
          sortField: 'department',
          cell: property => {
            let dep = get(property, 'department', '');
            switch (dep) {
              case 'new_developments':
                dep = 'new developments';
                break;
              default:
                break;
            }
            return (
              <>
                <div style={{ 'text-transform': 'capitalize' }}>
                  {dep}
                </div>
              </>
            )
          },
          width: "12%"
        },
        {
          name: 'Type',
          sortable: true,
          sortField: 'search_type',
          width: "8%",
          cell: property => (
            <>
              <div style={{ 'text-transform': 'capitalize' }}>
                {get(property, 'search_type')}
              </div>
            </>
          )
        },
        {
          name: 'Status',
          selector: row => row.status,
          sortable: true,
          sortField: 'status',
          width: "9%"
        },
        {
          name: 'Address',
          selector: row => row.display_address,
          sortable: true,
          sortField: 'display_address',
          width: "24%",
          cell: property => (
            <>
              <div style={{ 'word-break': 'normal' }}>
                {get(property, 'display_address')}
              </div>
            </>
          )
        },
        {
          name: 'Price',
          sortable: true,
          sortField: 'price',
          cell: property => {
            let priceFreq = (property.search_type === 'lettings') ? property.price_qualifier : '';
            switch (priceFreq) {
              case 'Per Annum':
                priceFreq = 'pa';
                break;
              case 'Per Month':
                priceFreq = 'pm';
                break;
              case 'Per Week':
                priceFreq = 'pw';
                break;
              case 'POA':
                priceFreq = 'poa';
                break;
              default:
                priceFreq = '';
            }
            return (
              <>
                {
                  `${EuroCurrencyFormatter(get(property, 'price'))} ${priceFreq}`
                }
              </>
            )
          },
          width: "13%"
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "office-user-mapping": {
    endpoint: {
      "uri": "content-manager/collection-types/application::office-user-mapping.office-user-mapping",
      excludeApiPrefix: true,
      "getUsersByOfficeId": {
        "uri": "office-user-mappings/office/users",
        excludeApiPrefix: true,
      },
      "getOfficesByLoggedUser": {
        "uri": "office-user-mappings/user/offices",
        excludeApiPrefix: true,
      },
      "delete": {
        "uri": "delete/1/office-user-mapping",
        excludeApiPrefix: false,
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Office',
          sortable: true,
          sortField: 'office.Office_Name',
          width: "25%",
          cell: office => (
            <>
              <div style={{ 'text-transform': 'capitalize' }}>
                {`${get(office, 'office.Office_Name')} ${(get(office, 'office.Branch_Location') ? (`(${get(office, 'office.Branch_Location')})`) : "")}`}
              </div>
            </>
          ),
        },
        {
          name: 'Users',
          sortable: false,
          sortField: 'department',
          cell: office => (
            <>
              <div style={{ 'text-transform': 'capitalize' }}>
                {get(office, 'admin_users.count')}
              </div>
            </>
          ),
          width: "50%"
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true,
      excludePublishedStatus: true
    }
  },
  "facilities": {
    endpoint: {
      "uri": "pu-facility"
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Facility',
          selector: row => row.facility,
          sortable: true,
          sortField: 'facility',
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true,
      excludePublishedStatus: true
    }
  },
  "settings": {
    endpoint: {
      "uri": "pu-setting",
    },
  },
  "file": {
    endpoint: {
      "uri": "pu-file",
      "upload": "upload",
      "delete": "",
      "getById": "",
      "getItems": ""
    },
    table: {
      columns: [
        {
          name: 'Thumbnail',
          cell: media => (
            <>
              <img src={
                (media.ext === '.zip') ? ZIP :
                  ((media.ext === '.pdf') ? PDF :
                    (get(media, 'url') ? get(media, 'url') :
                      NO_IMG))
              } style={{ width: "150px", height: "100px", marginBottom: "5px", marginTop: "5px" }} alt="" />
            </>
          ),
        },
        {
          name: 'Category',
          sortable: true,
          sortField: 'category',
          selector: row => row.category,
        },
        {
          name: 'Caption',
          sortable: true,
          sortField: 'caption',
          selector: row => row.caption,
        },
        {
          name: 'Order',
          sortable: true,
          sortField: 'order',
          selector: row => row.order,
        },
      ],
      excludeUser: true
    }
  },
  "auth": {
    endpoint: {
      excludeApiPrefix: true,
      excludeAuthHeader: true,
      "uri": "admin",
      "login": "login",
      "forgot-password": "forgot-password",
      "reset-password": "reset-password"
    }
  },
  "offices": {
    endpoint: {
      excludeApiPrefix: true,
      "uri": "offices",
      "getAll": "",
      "getMyHomeOffices": 'myhome/property-manager'
    },
    gql: {
      GET_OFFICES: `
        query GetOffices {
          offices(where: {_limit: -1}, sort: "Office_Name:ASC", publicationState: PREVIEW) {
          id
          Office_Name
          my_home_id
          Branch_Location
          Publish
          published_at
          }
        }
      `,
    }
  },
  "users": {
    endpoint: {
      excludeApiPrefix: true,
      "uri": "admin/users",
      "getAll": ""
    }
  },
  "teams": {
    endpoint: {
      excludeApiPrefix: true,
      "uri": "teams",
      "getAll": ""
    }
  },
};

export default PropertyUploaderConfig;